<template>
    <div>
        <div class="mybet" v-for="(market,index) in marketList" :key="index">
            <div v-if="position === 5" style="color: #c4c4c3; font-size: 11px;width: 100%;text-align: left; margin-top: 10px;background-color: #1d252c;line-height: 20px;padding-left: 5px">
                <i class="fa fa-file-code-o"></i> <span style="color: #ffffff">{{market.createTime|datef('MM월DD일 HH시mm분')}}</span>
                회원 <span style="color: #f4c600">{{market.seller.nickname}}</span>님 판매등록내역
            </div>
            <div v-if="position === 6" style="color: #c4c4c3; font-size: 11px;width: 100%;text-align: left; margin-top: 10px;background-color: #1d252c;line-height: 20px;padding-left: 5px">
                <i class="fa fa-file-code-o"></i> <span style="color: #ffffff">{{market.createTime|datef('MM월DD일 HH시mm분')}}</span>
                <span style="color: #f4c600">{{market.seller.nickname}}</span>님 <span>{{market.price|comma}}</span>BP 판매등록내역
                <br/>
                <i class="fa fa-file-code-o"></i> <span style="color: #ffffff">{{market.updateTime|datef('MM월DD일 HH시mm분')}}</span> 구매성공
            </div>
            <div v-if="market.betInfo.isinplay === sportsConst.INPLAY_NOT">
                <div class="sports" v-for="(betInfoGame,index2) in market.betInfo.betInfoGameList" :key="index2">

                    <div class="match_area">
                        <div class="league">
                            <img alt="" :src="betInfoGame.game.league.imageUrl" class="league_img">
                            <span class="league_name">{{betInfoGame.game.league.leagueName}}</span>

                            <span class="score text-orange"
                                  v-if="betInfoGame.game.gameResult !== sportsConst.GAME_RESULT_CANCELED
                                && betInfoGame.game.gameResult !== sportsConst.GAME_RESULT_SPECIAL
                                && betInfoGame.game.gameResult !== sportsConst.GAME_RESULT_WATING">
                                    {{betInfoGame.game.homeTeamScore}}:{{betInfoGame.game.awayTeamScore}}

                            </span>
                            <span class="score text-red"
                                  v-if="betInfoGame.game.gameResult === sportsConst.GAME_RESULT_CANCELED">
                                      취소
                                    </span>
                            <span class="score text-orange"
                                  v-if="betInfoGame.game.gameResult === sportsConst.GAME_RESULT_SPECIAL">
                                      적특
                                    </span>
                            <span class="score text-dark"
                                  v-if="betInfoGame.game.gameResult === sportsConst.GAME_RESULT_WATING">
                                     대기중
                                    </span>
                            <span class="st"
                                  style="float: right">{{betInfoGame.game.startTime|datef('MM/DD HH:mm')}}</span>
                        </div>
                        <div class="match clearfix">
                            <div class="start_time">
                                {{betInfoGame.game.startTime|datef('DD일 HH:mm')}}
                            </div>
                            <div class="team_info">
                                <div class="detail">
                                    <div class="home bg_1x2"
                                         :class="{'active_sports':betInfoGame.selectedVal === sportsConst.WAY_HOME}">
                                        <span class="home_name">{{betInfoGame.game.homeTeamName}}</span>
                                        <span class="home_odds">
                                            <i v-if="betInfoGame.game.kind === sportsConst.GAME_KIND_HANDICAP"
                                               class="fa fa-h-square h_icon"></i>
                                            <i v-if="betInfoGame.game.kind === sportsConst.GAME_KIND_OVERUNDER"
                                               class="fa fa-arrow-up o_icon"></i>
                                            {{betInfoGame.homeTeamOdds}}
                                        </span>
                                    </div>
                                    <!--무 3way-->
                                    <div class="draw bg_1x2"
                                         v-if="betInfoGame.game.kind === sportsConst.GAME_KIND_1X2 && betInfoGame.drawOdds > 0"
                                         :class="{'active_sports':betInfoGame.selectedVal === sportsConst.WAY_DRAW}">
                                        <span class="draw_bedang">{{betInfoGame.drawOdds}}</span>
                                    </div>
                                    <div class="draw ho"
                                         v-if="betInfoGame.game.kind === sportsConst.GAME_KIND_1X2 && betInfoGame.drawOdds <= 0">
                                        <span>vs</span>
                                    </div>
                                    <!--핸디캡 오버언더-->
                                    <div v-if="betInfoGame.game.kind === sportsConst.GAME_KIND_HANDICAP"
                                         class="draw ho">
                                        {{betInfoGame.handicapVal}}
                                    </div>
                                    <div v-if="betInfoGame.game.kind === sportsConst.GAME_KIND_OVERUNDER"
                                         class="draw ho">
                                        {{betInfoGame.overunderVal}}
                                    </div>

                                    <div class="away bg_1x2"
                                         :class="{'active_sports':betInfoGame.selectedVal === sportsConst.WAY_AWAY}">
                                        <span class="away_name">
                                          {{betInfoGame.game.awayTeamName}}
                                        </span>
                                        <span class="away_odds">
                                            {{betInfoGame.awayTeamOdds}}
                                            <i v-if="betInfoGame.game.kind === sportsConst.GAME_KIND_HANDICAP"
                                               class="fa fa-h-square h_icon"></i>
                                            <i v-if="betInfoGame.game.kind === sportsConst.GAME_KIND_OVERUNDER"
                                               class="fa fa-arrow-down o_icon"></i>
                                        </span>
                                    </div>
                                </div>
                            </div>
                            <div class="more">
                                 <span class="text-orange" v-if="betInfoGame.betGameResult === 1">
                                       적중
                                  </span>
                                <span class="text-red" v-if="betInfoGame.betGameResult === 2">
                                            낙첨
                                </span>
                                <span class="text-orange" v-if="betInfoGame.betGameResult === 3">
                                     적특
                                  </span>
                                <span class="text-dark" v-if="betInfoGame.betGameResult === 4">
                                     취소
                                  </span>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
            <div v-if="market.betInfo.isinplay === sportsConst.INPLAY_YES">
                <div class="sports" v-for="(betInfoGame,index2) in market.betInfo.betInfoGameList"
                     :key="index2">
                    <div class="match_area">
                        <div class="league">
                            <img alt="" :src="betInfoGame.game.league.imageUrl" class="league_img">
                            <span class="league_name">{{betInfoGame.game.league.leagueName}}</span>
                            <span class="inplay_team_name">{{betInfoGame.game.homeTeamName}} vs {{betInfoGame.game.awayTeamName}}</span>
                        </div>
                        <ul>
                            <li>
                                <table class="table">
                                    <tr>
                                        <th>승부타임</th>
                                        <th>구분</th>
                                        <th>선택항목</th>
                                        <th>선택배당</th>
                                        <th>베팅결과</th>
                                    </tr>
                                    <tr>
                                        <td>{{betInfoGame.ito1}}</td>
                                        <td>{{betInfoGame.ito2}}</td>
                                        <td>{{betInfoGame.ito3}}</td>
                                        <td>{{betInfoGame.selectedOdds}} <span v-if="betInfoGame.score !== undefined"
                                                                               class="score">{{' ('+betInfoGame.score+')'}}</span>
                                        </td>
                                        <td>
                                        <span class="text-dark" v-if="betInfoGame.betGameResult === 0">
                                                대기
                                              </span>
                                            <span class="text-orange" v-if="betInfoGame.betGameResult === 1">
                                                적중
                                         </span>
                                            <span class="text-red" v-if="betInfoGame.betGameResult === 2">
                                            낙첨
                                          </span>
                                            <span class="text-orange" v-if="betInfoGame.betGameResult === 3">
                                             적특
                                          </span>
                                            <span class="text-dark" v-if="betInfoGame.betGameResult === 4">
                                             취소
                                          </span>
                                        </td>
                                    </tr>
                                </table>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
            <div class="betInfo">
                <span class="time">
                  <i class="fa fa-clock-o"></i>&nbsp;
                  <i>{{market.betInfo.createTime|datef('MM/DD HH:mm')}}</i>
                </span>

                <span class="betCash">베팅금: {{market.betInfo.betCash|comma}}</span>
                <span class="odds">배당: {{market.betInfo.betOddsTotal}}</span>

                <span class="wincash">
                예상당첨:
                {{(market.betInfo.totalCash+market.betInfo.bonusCash)|comma}}&nbsp;({{market.betInfo.bonusCash|comma}})
                </span>

                <span
                        v-if="market.betInfo.betResult === sportsConst.BET_RESULT_WAITING" class="text-dark">결과대기</span>
                <span class="text-orange"
                      v-if="market.betInfo.betResult === sportsConst.BET_RESULT_WIN"><i class="fa fa-trophy"></i> 당첨</span>
                <span class="text-red"
                      v-if="market.betInfo.betResult === sportsConst.BET_RESULT_LOSE">낙첨</span>
                <span class="text-orange"
                      v-if="market.betInfo.betResult === sportsConst.BET_RESULT_SPECIALCASE
                                  || (market.betInfo.betOddsTotal === 1 && market.betInfo.betResult !== sportsConst.BET_RESULT_SPECIALCASE)">[적특]</span>
                <span class="text-dark"
                      v-if="market.betInfo.betResult === sportsConst.BET_RESULT_CANCEL">[취소]
                            </span>

                <div v-if="position === 4 && market.status === 1" style="display: block;width: 100%;text-align: center">
                    판매금액 {{market.price|comma}}BP <button class="btn03" @click="cancelSell(market.id)" style="margin-left: 5px;padding: 5px 0;width: 80px">판매취소</button>
                </div>
                <div v-if="position === 4 && market.status === 2"  style="display: block;width: 100%;text-align: center">
                    판매금액 {{market.price|comma}} 판매완료
                </div>
                <div v-if="position === 4 && market.status === 0"  style="display: block;width: 100%;text-align: center">
                    판매금액 <span style="color: #f19f09">{{market.price|comma}} </span>취소됨
                </div>
                <div v-if="position === 5" style="display: block;width: 100%;text-align: center">
                    판매금액 <i style="color: #f19f09;font-style: normal">{{market.price|comma}}</i> BP
                    <button v-if=" market.owner === 0" class="btn03" @click="buy(market.id)" style="margin-left: 5px;padding: 5px 0;width: 80px">구매하기</button>
                </div>
            </div>
        </div>
    </div>

</template>

<script>
    import {cancelBet, deleteBet} from "../../network/sportsBetRequest";
    import sportsConst from "../../common/sportsConst";
    import {buy, cancelSell, sell} from "../../network/sportsMarketRequest";

    export default {
        name: "MarketBetListComp",
        data() {
            return {
                sportsConst,
            }
        },
        props: {
            marketList: {
                type: Array,
                default: []
            },
            position: {
                type: Number,
                default: 4 // 4:판매내역 5:구매내역
            }
        },
        methods: {

            cancelSell(id) {
                this.$swal({
                    title: '참여내역 판매를 취소하시겠습니까?',
                    type: 'question',
                    confirmButtonText: ' 예 ',
                    cancelButtonText: '아니오'
                }).then((res) => {
                    if (res.value) {
                        cancelSell(id).then(res => {
                            if (res.data.success) {
                                this.$emit('cancelSellSuccess')
                            } else {
                                this.$swal({
                                    title: res.data.msg,
                                    type: 'error',
                                    showCancelButton: false,
                                    showConfirmButton: true
                                })
                            }
                        })
                    }
                })


            },
            buy(id){
                this.$swal({
                    title: '참여내역을 구매하시겠습니까 ?',
                    type: 'question',
                    confirmButtonText: ' 예 ',
                    cancelButtonText: '아니오'
                }).then((res) => {
                    if (res.value) {
                        buy(id).then(res => {
                            if (res.data.success) {
                                this.$emit('buySuccess')
                            } else {
                                this.$swal({
                                    title: res.data.msg,
                                    type: 'error',
                                    showCancelButton: false,
                                    showConfirmButton: true
                                })
                            }
                        })
                    }
                })
            }
        }
    }
</script>

<style scoped>
    .league .score {
        float: right;
        margin: 0 3px;
    }

    .league .st {
        display: none;
    }

    .betInfo {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        background-color: #3e3e3e;
        line-height: 36px;
        box-shadow: inset 0 2px 13px rgba(76, 76, 76, 0.92);
        margin-bottom: 20px;
        border-bottom: 4px solid #00ca18;
    }

    .betInfo span {
        display: block;
        width: 33.3%;
        color: #c4c4c3;
    }

    .match_area table {
        font-size: 12px;
    }

    .match_area table th {
        color: #c4c4c3;
    }

    .match_area table th, td {
        height: 30px;
        line-height: 30px;
        border-bottom: 1px solid #1a4050;
    }

    .match_area .inplay_team_name {
        margin-left: 20px;
        color: #f4c600;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }

    @media screen and (max-width: 1024px) {
        .league .st {
            display: inline-block;
            color: #c4c4c3;
            margin-right: 20px;
        }

        .match_area .inplay_team_name {
            margin-left: 5px;
        }
    }


</style>